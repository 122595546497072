<template>
  <GoldflamMultiselect
    v-model="inputVal"
    :disable="disableAll"
    :selectable-items="allDatasetTags"
    :placeholder="placeholderLabel"
    :tag-placeholder="labels.tagPlaceholder"
    :empty-label="labels.listEmpty"
    :taggable="allowTagCreation"
    :create-tag-function="createTag"
  >
    <template v-slot:tag="props">
      <DatasetTagBadge
        v-if="props.option.value"
        :tag="props.option.value"
        :allow-removal="allowAutoTagRemoval || (!props.option.value.auto_tag && !disableAll)"
        :remove-function="removeTag"
        @update-negation="updateNegation(props.option.value.id)"
        :is-negated="props.option.isNegated"
      />
    </template>
  </GoldflamMultiselect>
</template>

<script>
import GoldflamMultiselect from '@/components/GoldflamMultiselect'
import DatasetTagBadge from '@/components/DatasetTagBadge'
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'

export default {
  name: 'DatasetTagMultiselect',
  components: { GoldflamMultiselect, DatasetTagBadge },
  mixins: [ApiMixin],
  props: {
    value: {
      required: true,
      type: Array
    },
    includeAutoTags: {
      required: false,
      type: Boolean,
      default: true
    },
    allowAutoTagRemoval: {
      required: false,
      type: Boolean,
      default: true
    },
    allowTagCreation: {
      required: false,
      type: Boolean,
      default: false
    },
    allowTagNegation: {
      required: false,
      type: Boolean,
      default: false
    },
    useDefaultTagIds: {
      required: false,
      type: Boolean,
      default: false
    },
    defaultTagIds: {
      required: false,
      type: Array,
      default: () => []
    },
    disableAll: {
      required: false,
      type: Boolean,
      default: false
    },
    isOrFilter: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.loadAllDatasetTags()
  },
  data() {
    return {
      allDatasetTags: [],
      labels: {
        placeholder: this.$t('components.datasetTagMultiselect.default-placeholder'),
        placeholderOrFilter: this.$t('components.datasetTagMultiselect.default-placeholder-or-filter'),
        placeholderTaggable: this.$t('components.datasetTagMultiselect.default-placeholder-taggable'),
        tagPlaceholder: this.$t('components.datasetTagMultiselect.default-tag-placeholder'),
        listEmpty: this.$t('components.datasetTagMultiselect.list-empty-label')
      }
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    placeholderLabel() {
      return this.allowTagCreation
        ? this.labels.placeholderTaggable
        : this.isOrFilter
        ? this.labels.placeholderOrFilter
        : this.labels.placeholder
    }
  },
  methods: {
    updateNegation(id) {
      if (this.allowTagNegation) {
        this.inputVal.forEach(e => {
          if (e.value.id === id) {
            e.isNegated = !e.isNegated
          }
        })
      }
    },
    loadAllDatasetTags() {
      let self = this
      let url = '/dataset_tags'
      if (!this.includeAutoTags) {
        url += '?auto_tag=false'
      }
      this.getRequest(
        url,
        new RequestConfig().onSuccess(res => {
          let defaultTags = []
          res.data.forEach(datasetTag => {
            self.allDatasetTags.push({
              value: datasetTag,
              text: datasetTag.name,
              isNegated: false
            })
            if (self.useDefaultTagIds && self.defaultTagIds) {
              self.defaultTagIds.forEach(id => {
                if (parseInt(id) < 0 && parseInt(id) * -1 === datasetTag.id) {
                  defaultTags.push({
                    value: datasetTag,
                    text: datasetTag.name,
                    isNegated: true
                  })
                } else if (parseInt(id) === datasetTag.id) {
                  defaultTags.push({
                    value: datasetTag,
                    text: datasetTag.name,
                    isNegated: false
                  })
                }
              })
              self.inputVal = defaultTags
            }
          })
        })
      )
    },
    removeTag(tag) {
      this.inputVal.forEach(item => {
        if (item.value.name === tag.name && tag.id !== null) {
          item.isNegated = false
          this.$emit('updateTagsToDelete', tag)
        }
      })
      this.inputVal = this.inputVal.filter(item => item.value.name !== tag.name)
    },
    createTag(newTag) {
      let isNewTag = true
      this.inputVal.forEach(tag => {
        if (tag.text === newTag) {
          isNewTag = false
        }
      })
      if (isNewTag) {
        const tag = {
          text: newTag,
          value: {
            id: null,
            auto_tag: false,
            name: newTag
          }
        }
        this.inputVal.push(tag)
      }
    }
  }
}
</script>

<style>
.multiselect__tags-wrap {
  display: inline-flex !important;
}
</style>
