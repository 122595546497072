<template>
  <b-modal
    v-model="showDeleteModal"
    :title="title"
    :cancel-title="$t('general.cancel')"
    cancel-variant="outline-secondary"
    :ok-title="$t('general.delete')"
    ok-variant="danger"
    @ok="deleteFunction"
    centered
    :header-bg-variant="headerBgVariant"
    :header-text-variant="headerTextVariant"
    :body-bg-variant="bodyBgVariant"
    :body-text-variant="bodyTextVariant"
    :footer-bg-variant="footerBgVariant"
    :footer-text-variant="footerTextVariant"
  >
    {{ deleteMessage }}
  </b-modal>
</template>

<script>
export default {
  name: 'DeleteModal',
  props: {
    value: {
      required: true,
      type: Boolean
    },
    deleteFunction: {
      required: true,
      type: Function
    },
    title: {
      required: true,
      type: String
    },
    deleteMessage: {
      required: true,
      type: String
    }
  },
  computed: {
    showDeleteModal: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  data() {
    return {
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark'
    }
  }
}
</script>

<style scoped></style>
