<template>
  <b-badge
    class="p-0 pl-1 mb-2 font-weight-normal d-inline-flex align-items-baseline"
    style="cursor: pointer"
    :class="marginClasses"
    :key="tag.id"
    :variant="badgeVariant"
    v-b-hover="handleHover"
    @click="changeNegationStatus"
  >
    <b-icon
      icon="asterisk"
      scale="0.8"
      v-if="tag.auto_tag"
      class=""
      v-b-tooltip.bottom="$t('components.datasetTagBadge.auto-tag')"
    />
    <button class="py-1 pl-1 pr-2 badge-text-button">{{ tag.name }}</button>
    <b-button
      v-if="allowRemoval"
      class="m-0 ml-1 align-self-stretch badge-removal-button"
      :variant="isHovered ? 'danger' : 'transparent'"
      size="xs"
      @click="removeFunction(tag)"
    >
      <b-icon icon="x" scale="1.6" />
    </b-button>
  </b-badge>
</template>

<script>
export default {
  name: 'DatasetTagBadge',
  props: {
    tag: {
      required: true,
      type: Object
    },
    allowRemoval: {
      required: false,
      type: Boolean,
      default: false
    },
    removeFunction: {
      required: false,
      type: Function
    },
    marginClasses: {
      required: false,
      type: String,
      default: 'ml-2'
    },
    isNegated: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isHovered: false
    }
  },
  computed: {
    badgeVariant() {
      return this.isNegated ? 'warning' : this.tag.auto_tag ? 'info' : 'success'
    },
    isNegatedSync: {
      get() {
        return this.isNegated
      },
      set(val) {
        this.$emit('update-negation', val)
      }
    }
  },
  methods: {
    changeNegationStatus() {
      this.isNegatedSync = !this.isNegatedSync
    },
    handleHover(hovered) {
      this.isHovered = hovered
    }
  }
}
</script>

<style>
.badge-removal-button {
  padding: 0.1rem 0.2rem !important;
  border-radius: 0.25rem !important;
}
.badge-text-button {
  border: 0px;
  background: transparent;
  color: white;
}
.badge-warning .badge-text-button {
  color: black;
}
.btn-transparent {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
