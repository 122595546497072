<template>
  <div>
    <!-- https://vue-multiselect.js.org/#sub-props -->
    <multiselect
      id="multiUserSelect"
      v-model="inputVal"
      :options="selectableItems"
      :multiple="true"
      :disabled="disable"
      :close-on-select="true"
      :clear-on-select="true"
      :hide-selected="true"
      :placeholder="placeholder"
      :taggable="taggable"
      :tag-placeholder="tagPlaceholder"
      @tag="createTagFunction"
      :show-no-results="false"
      track-by="text"
      label="text"
      :select-label="$t('components.multiselect.select-label')"
      :select-group-label="$t('components.multiselect.select-group-label')"
      :selected-label="$t('components.multiselect.selected-label')"
      :deselect-label="$t('components.multiselect.deselect-label')"
      :deselect-group-label="$t('components.multiselect.deselect-group-label')"
    >
      <template v-slot:noOptions>{{ emptyLabel }}</template>
      <!-- pass on scoped slots from the multiselect component to the parent component -->
      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
    </multiselect>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'GoldflamMultiselect',
  components: { Multiselect },
  props: {
    value: {
      required: true,
      type: Array
    },
    selectableItems: {
      required: true,
      type: Array
    },
    placeholder: {
      required: false,
      type: String,
      default: function () {
        this.$t('components.multiselect.default-placeholder')
      }
    },
    emptyLabel: {
      required: false,
      type: String,
      default: function () {
        this.$t('components.multiselect.list-empty-label')
      }
    },
    taggable: {
      required: false,
      type: Boolean,
      default: false
    },
    tagPlaceholder: {
      required: false,
      type: String,
      default: function () {
        this.$t('components.multiselect.default-tag-placeholder')
      }
    },
    createTagFunction: {
      required: false,
      type: Function
    },
    disable: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />

<style>
.multiselect__input {
  margin-top: 0.8rem;
}
</style>
