import { RequestConfig } from '@/mixins/ApiMixin'

// also requires ApiMixin and NotificationMixin in the caller component
export const DatasetDeletionMixin = {
  data() {
    return {
      datasetDeleteModal: {
        show: false,
        datasetId: null
      }
    }
  },
  methods: {
    openDeleteModal(datasetId) {
      this.datasetDeleteModal.show = true
      this.datasetDeleteModal.datasetId = datasetId
    },
    deleteDataset() {
      let self = this
      return this.deleteRequest(
        `/datasets/${self.datasetDeleteModal.datasetId}`,
        new RequestConfig()
          .onSuccess(() => {
            self.displaySuccess(self.$t('dataset.deletion-success-msg'))
          })
          .onError(() => {
            self.displayError(self.$t('dataset.deletion-error-msg'))
          })
      )
    }
  }
}
